import React from 'react'
import errorImage from './../img/payment_error.svg'
import { Jumbotron, Container } from 'react-bootstrap'

const AntwerpenInfoB = () => {
  return (
    <Container>
      <Jumbotron className='text-center'>
        <img className='mb-4' src={errorImage} alt='error' />
        <p>Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Antwerpen : Vanaf 1/4/2023 is het niet langer mogelijk zijn mandaten via dit platform te verzekeren, ook niet als ze toegekend werden voor 1/1/2023.</p>
        <p>Voor vragen kan u contact nemen met <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</p>
      </Jumbotron>
    </Container>
  )
}

export default AntwerpenInfoB
