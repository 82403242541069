import React from 'react'
import errorImage from './../img/payment_error.svg'
import { Jumbotron, Container } from 'react-bootstrap'

const AntwerpenInfoA = () => {
  return (
    <Container>
      <Jumbotron className='text-center'>
        <img className='mb-4' src={errorImage} alt='error' />
        <p>Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Antwerpen : Sinds 1/1/2023 is het niet langer mogelijk om mandaten toegekend vanaf 1/1/2023 via dit platform te verzekeren.</p>
        <p>Voor vragen kan u contact nemen met <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</p>
      </Jumbotron>
    </Container>
  )
}

export default AntwerpenInfoA
