import React from 'react'

const AntwerpenInfo = (props) => {
  return (
    <div role="alert" className='warning-alert mt-5'>
      {props.variant === '2022' && (
        <span>Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Antwerpen : Vanaf 1/1/2023 zal het niet langer mogelijk zijn om mandaten toegekend vanaf 1/1/2023 via dit platform te verzekeren. Voor vragen kan u contact nemen met <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</span>
      )}
      {props.variant === '2023' && (
        <span>Belangrijke boodschap voor de mandatarissen met hoofdinschrijving bij Balie Antwerpen : Vanaf 1/1/2023 is het niet langer mogelijk om mandaten toegekend vanaf 1/1/2023 via dit platform te verzekeren. Mandaten toegekend voor 1/1/2023 kunnen wel nog verzekerd worden via dit platform t.e.m. 1/4/2023. Voor vragen kan u contact nemen met <a href="mailto:spccb@vanbreda.be">spccb@vanbreda.be</a>.</span>
      )}
    </div>
  )
}

export default AntwerpenInfo
