import React, { useEffect } from 'react'
import flexibility from 'flexibility'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ProspectContextProvider } from './contexts/ProspectContext'

import Header from './components/Header'
import Footer from './components/Footer'

import ProspectWrapper from './components/ProspectWrapper'
import PlansOrDownloads from './components/PlansOrDownloads'

import Subscribe from './pages/Subscribe'
import Diplad from './pages/Diplad'
import AssurMifid from './pages/AssurMifid'
import Privacy from './pages/Privacy'
import CookiePolicy from './pages/CookiePolicy'
import PageNotFound from './pages/PageNotFound'
import AntwerpenInfoA from './pages/AntwerpenInfoA'
import AntwerpenInfoB from './pages/AntwerpenInfoB'
import Report from './pages/Report'

const App = () => {
  useEffect(() => {
    flexibility(document.body)
  }, [])

  const history = createBrowserHistory()
  return (
    <div className='App'>
      <ProspectContextProvider>
        <Router basename='/ui' history={history}>
          <Header />
          <div id='main'>
            <Switch>
              <Route exact path='/prospect/:prospectId'>
                <ProspectWrapper>
                  <PlansOrDownloads />
                </ProspectWrapper>
              </Route>
              <Route exact path='/prospect/:prospectId/:planId/subscribe'>
                <ProspectWrapper>
                  <Subscribe />
                </ProspectWrapper>
              </Route>
              <Route
                exact
                path='/prospect/:prospectId/download'
                render={() => {
                  return (
                    <ProspectWrapper />
                  )
                }}
              />
              <Route exact path='/form'>
                <Diplad />
              </Route>
              <Route exact path='/assurmifid'>
                <AssurMifid />
              </Route>
              <Route exact path='/privacy'>
                <Privacy />
              </Route>
              <Route exact path='/cookie-policy'>
                <CookiePolicy />
              </Route>
              <Route exact path='/report'>
                <Report />
              </Route>
              <Route exact path='/antwerpen-info-a'>
                <AntwerpenInfoA />
              </Route>
              <Route exact path='/antwerpen-info-b'>
                <AntwerpenInfoB />
              </Route>
              <Route>
                <PageNotFound />
              </Route>
            </Switch>
          </div>
          <Footer />
        </Router>
      </ProspectContextProvider>
    </div>
  )
}

export default App
